h1{
  display: grid;
  justify-content: center;
}

.parent-container{
  display: grid;
  grid-template-rows: max-content 2fr;
}
.leftbar{
  background-color: whitesmoke;
    padding: 16px;
    border-radius: 8px;
    display: grid;
}

.canvas-bar{
display: grid;
grid-auto-flow: row;
gap:16px;
}

.canvas-style{
  border:4px solid cornflowerblue;
  border-radius: 8px;
  /*scale: 0.125;
    transform: translate(-52000px, -36600px);*/
}

.fileupload-container{
  display: grid;
    justify-content: center;
    position: fixed;
    top: 64px;
    left: 10px;
    z-index: 10;
}

.canvas-container{
    margin: 16px;
    border-radius: 8px;
    position: relative;
    width: 1500px;
    height: 1050px;
    border: 2px solid silver;
    background: white;
}

.canvas-container img{
  position: absolute;
}

img{
  width: 96px;
  height: 96px;
  display: grid;
  margin:auto;
}

.img-container{
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap:16px;
  overflow: auto;
  padding: 4px;
  padding-left: 64px;
  font-size: 12px;
  color:cornflowerblue;
}
.img-actions{
  display: grid;
    grid-auto-flow: column;
    gap: 4px;
    padding: 8px;
    border-bottom: 1px solid gainsboro;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  /*border: 1px solid #ccc; */
    display: grid;
    /* padding: 6px 12px; */
    cursor: pointer;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background: cornflowerblue;
}

.img-item{
  transition: .6s;
  border: 1px solid transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 
  0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
}
.active{
  border: 1px solid cornflowerblue;
}

.canvas-workarea{
  display: grid;
  grid-template-columns: 270px 1fr;
  position: relative;
}
.canvas-workarea ul{
  list-style: none;
  padding: 0;
}
.tree-listitem{
  display: grid;
  align-items: center;
  cursor: pointer;
}
.tree-listitem-meta{
  display: grid;
    grid-template-columns: 32px 1fr;
    background-color: gainsboro;
    margin:4px;
}
.tree-listitem-meta img{
  width: 32px;
  height: 32px;
}
.listitem-oper{
  display: grid;
  gap:8px;
}

.listitem-location{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(80px,1fr));
  gap:8px;
}

.new-canvas{
  position: relative;
  
  
}

.new-canvas img{
  position: absolute;
  width: 200px;
  height: 200px;
}

.new-canvas img:nth-child(1){
  left:200px;
  z-index: 10;
  top:39px;
}
.new-canvas img:nth-child(2){
  left:265px;
  top:35px;
  z-index: 9;
}
.new-canvas img:nth-child(3){
  left:360px;
  top:25px;
  z-index: 8;
}
.new-canvas img:nth-child(4){
  left:460px;
  top:16px;
  z-index: 7;
}
.new-canvas img:nth-child(5){
  left: 524px;
    top: 62px;
    z-index: 11;
}